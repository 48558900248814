@import 'animate.css/animate.min.css';

.radio-request-type {
  justify-content: center;
}

.form-request {
  width: 100%;
}

.some-page-wrapper {
  /*background-color: red;*/
}

.hide {
  display: none;
}

.radio-request-type {
  margin-bottom: 10px;
}

.datePickerStart {
  margin-right: 5px;
}

.datePickerEnd {
  margin-left: 5px;
}

.note {
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.dataRangeWidth {
  width: 100%;
}
.dataRangeWidth .rdrMonth {
  margin: 0 auto;
  width: 127.667em;
}

.rdrDateDisplayWrapper {
  background-color: rgb(0 0 0 / 0%) !important;
}

.rdrCalendarWrapper {
  background: none !important;
}

.hoursOffType {
  margin-left: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  /*margin: 5px;*/
}

.blue-column {
  background-color: blue;
}

.green-column {
  background-color: green;
}

/* Nascondere lo spinner predefinito */
.sweet-alert-container {
  z-index: 9999;
}

.swal2-html-container {
  padding-top: 4vh;
}

.sweet-alert-container .swal2-loader {
  display: none !important;
  padding: 5px;
}

.sweet-popup-custom-width {
  width: 16vw !important;
  animation: bounce 3s infinite;
}

.sweet-popup-custom-width-mobile {
  width: 80vw;
  animation: bounce 3s infinite;
}

.my-custom-spinner {
  display: block;
  margin: 0 auto;
  animation: bounce 3s infinite;
}

.sweet-confirm-button {
  background-color: '#289b38' !important;
}

.swal2-confirm {
  background-color: #289b38 !important;
}

.my-sweet-alert {
  position: fixed !important; /* Override della posizione */
  top: 60px !important; /* Sposta in giù di 50px */
  left: 50px !important; /* Sposta a destra di 50px */
}

.my-sweet-alert-absence {
  z-index: 9999;
  position: fixed !important; /* Override della posizione */
  top: 50vh !important; /* Sposta in giù di 50px */
  left: 50vw !important; /* Sposta a destra di 50px */
}

.swal-button {
  border-radius: 25% !important;
  border: none !important;
}

.css-aoiwcp-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-disabled {
  display: none !important;
}
