html,
body,
#root {
  /* height: 100%; */
  /*font-family: 'Campton-SemiBold', 'Helvetica', 'Arial', 'Verdana', 'sans-serif';*/
  font-family: 'Montserrat', serif;
  /* background-image: url('/public/favicon/Progettosenzatitolo.png');
  background-repeat: no-repeat;
  background-size: cover; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

footer {
  position: fixed;
  bottom: 5%;
  width: 100%;
}

.footer-text {
  font-size: small;
  text-align: center;
  flex: 1 1 auto;
}

.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.todo-form {
  width: 60%;
}

.todo-list {
  width: 60%;
}

.todo-view {
}

.todo-edit {
}

.todo-item {
}

.todo-label {
  font-size: large;
  margin-right: 22%;
  margin-left: 3%;
}

.todo-view-btn {
  float: right;
}

.absence-row-pending {
  background: rgba(185, 184, 184, 0.824);
}

.absence-row-accepted {
  background: rgb(40, 155, 56, 0.5);
}

.absence-row-rejected {
  background: rgb(231, 52, 57, 0.5);
}
.absence-row-ferie {
  background: rgb(40, 155, 56, 0.5);
}
.absence-row-permesso {
  background: rgb(44, 119, 189, 0.5);
}

.ti-logo {
  height: 50px;
}

/* App.css
.back {
  background-image: url('../assets/doodad.png');
  background-position: center;
  height: 100vh; 
  width: 100vw; 
} */
