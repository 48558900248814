.login {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: rgb(15, 15, 15);
  font-size: 36px;
  /*padding: 20px;*/

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: purple;
  flex: 1;
  background: url('../assets/img-slide-1.png') no-repeat;
  background-size: cover;
}
